



























import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'

import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import ExtendedHelpMessage from 'piramis-base-components/src/components/ExtendedHelpMessage.vue'

import { Component, Mixins } from 'vue-property-decorator'
import CurrentModule from "@/components/Modules/components/CurrentModule.vue";
import TempCurrentModuleMixin from "@/includes/logic/Modules/mixins/TempCurrentModuleMixin";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";

@Component({
  'components': {
    CurrentModule,
    CenteredColumnLayout,
    PageTitle,
    ExtendedHelpMessage
  },
})
export default class ReputationExtendedXp extends Mixins(TempCurrentModuleMixin) {
  created() {
    this.setCurrentModule(ModuleTypesEnum.XpHandlerModule)
  }
}
